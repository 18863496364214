class MastheadCardGloIT extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('MastheadCard before load');
	}

	async setData(){
		this.data.loggedIn = Utils.getCookie('commerce-auth-verify') === 'true';

		if(this.data.loggedIn){
			const requestCustomerDetails = await Commerce.getCustomerDetails();
			const customer = requestCustomerDetails.data.customer ? requestCustomerDetails.data.customer : '';
			
			const loyalty_card = document.querySelector("[data-component-id = loyalty_card]");
			if (loyalty_card) {
				var link_img=loyalty_card.querySelector(".bat-card-gloit--masthead-img a");
				var cta = loyalty_card.querySelector(".bat-card-gloit--masthead-main a");
				if(customer){
					link_img.href = "/it/it/myaccount/insiders-club";
					cta.href = "/it/it/myaccount/insiders-club";
				}else{
					link_img.href="/it/it/insiders-club-info"
					cta.href="/it/it/insiders-club-info"
				}
			}
		}

		if($(this).hasClass("glo-monday")){
			if(this.data.loggedIn){
				document.querySelector("bat-card-gloitmasthead.glo-monday .bat-card-gloit--masthead-text h3 span").innerHTML = "TORNA L'OFFERTA <br> DEL glo™ MONDAY<sup>4</sup>";
				document.querySelector("bat-card-gloitmasthead.glo-monday .bat-card-gloit--masthead-text p span").innerHTML = "Acquista HYPER PRO:<br>in omaggio un ticket per<br>cinema, teatri e musei";
				document.querySelector("bat-card-gloitmasthead.glo-monday .bat-card-gloit--masthead-img a").href = window.location.href.indexOf("uat1") > 0 ? '/it/it/newblog/post/glo-monday-luglio' : '/it/it/blog/post/glo-monday-luglio';
				document.querySelector("bat-card-gloitmasthead.glo-monday .bat-card-gloit--masthead-ctalist a").href=window.location.href.indexOf("uat1") > 0 ? '/it/it/newblog/post/glo-monday-luglio' : '/it/it/blog/post/glo-monday-luglio';
			}else{
				document.querySelector("bat-card-gloitmasthead.glo-monday .bat-card-gloit--masthead-img a").href = window.location.href.indexOf("uat1") > 0 ? '/it/it/myaccount/account/login?fromMastheadGloMonday=true&link=/it/it/newblog/post/glo-monday-luglio' : '/it/it/myaccount/account/login?fromMastheadGloMonday=true&link=/it/it/blog/post/glo-monday-luglio';
				document.querySelector("bat-card-gloitmasthead.glo-monday .bat-card-gloit--masthead-ctalist a").href = window.location.href.indexOf("uat1") > 0 ?'/it/it/myaccount/account/login?fromMastheadGloMonday=true&link=/it/it/newblog/post/glo-monday-luglio' : '/it/it/myaccount/account/login?fromMastheadGloMonday=true&link=/it/it/blog/post/glo-monday-luglio';
			}
		}
		
	}

	afterLoad() {
		// console.log('MastheadCard After load');
		this.$el = $(this);
		this.setData();
	}

	beforeUpdate() {
		// console.log('MastheadCard before update');
	}

	afterUpdate() {
		this.setData();
		// console.log('MastheadCard after update');
	}

	unload() {
		// console.log('MastheadCard after unload');
	}
}

!customElements.get('bat-card-gloitmasthead') &&
	customElements.define('bat-card-gloitmasthead', MastheadCardGloIT);
